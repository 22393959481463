<template>
<div v-if="reloadForm == false">
     <div v-if="itemContent">
        <div v-for="item in itemContent" :key="item.clustertype_id">
            <Panel :header="item.clustertype_label" :toggleable="true">
                <template #icons>
                </template>
                <p>{{item.clustertype_description}}</p>
                <div class="card-container flex flex-wrap">
                    <Card 
                        v-for="(itemSlot, index) in item.clusterSlot" 
                        :key="itemSlot.clusterslot_id" 
                        style="width: 20rem; margin-bottom: 1em" 
                        :class="{'bg-teal-100': itemSlot.trans_cluster, 'surface-100': !itemSlot.trans_cluster}" 
                        class="m-2 card-buttom" 
                        @click="handleClick(itemSlot.clusterslot_id, item.clustertype_label)">
                            <template #title>
                                Slot {{index+1}}
                            </template>
                            <template #subtitle >
                                {{itemSlot.trans_cluster ? itemSlot.trans_cluster.cluster_name : "No item" }}
                            </template>
                            <template #content>
                                <p>{{itemSlot.trans_cluster ? truncatedDescription(itemSlot.trans_cluster.cluster_description) : "No Item"}}</p>
                            </template>
                    </Card>
                </div>
            </Panel>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">Content Not Found</h3>
    </div>
</div>
<div v-else>
    <SkeletonComponent />
</div>
</template>

<script>
import SkeletonComponent from '../../../../SkeletonComponent.vue';

export default {
    components: {
		'SkeletonComponent': SkeletonComponent,
	},
    data() {
        return {
            // loading
            reloadForm: false,

            project_id: null,

            itemContent: {},
        }
    },
    created() {
        this.project_id = this.$route.params.id;
        this.getContent(this.project_id);
    },
    methods: {
        truncatedDescription(description){
            if(description){
                const words = description.split(" ");
                return words.length > 10 ? words.slice(0, 10).join(" ") + "..." : description;
            } else {
                return "";
            }
        },
        handleClick(clusterslot_id, clustertype_label){
            if(clustertype_label == 'By Numbers of Beats'){
                this.$router.replace({ name: "project-detail-territory-mapping-by-numbers-of-beat", params: {id:this.project_id, clusterslot_id:clusterslot_id} })
            }else if(clustertype_label == 'By Numbers of Outlets'){
                this.$router.replace({ name: "project-detail-territory-mapping-by-numbers-of-outlet", params: {id:this.project_id, clusterslot_id:clusterslot_id} })
            }else if (clustertype_label == 'By Numbers of Working Hours per Day'){
                this.$router.replace({ name: "project-detail-territory-mapping-by-numbers-of-working-hours-per-day", params: {id:this.project_id, clusterslot_id:clusterslot_id} })
            }else{
                alert('Sorry Page Not Found');
            }
        },
        getContent(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-mapping',
                params: {
                    "project_id": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {
                    this.$store.commit('setErrors', {});
                    this.itemContent = res.data.data;
                }
            }, (this))
            .catch((err) => {
                this.itemContent = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>

<style>
.card-buttom {
    cursor: pointer;
    transition: transform 0.2s;
}

.card-buttom:hover {
    transform: scale(1.05);
}
</style>
